/* @tailwind base;
@tailwind components;
@tailwind utilities; */

:root,
.light,
.light-theme,
.radix-themes {
  --color-background: var(--gray-2);
  /* --color-background: #fff; */
  --header-background: var(--gray-2);
  /* --header-background: #fff; */
  --footer-background: var(--gray-3);
}

:root,
.light,
.light-theme {
  --blue-1: #fdfdfe;
  --blue-2: #f7f9ff;
  --blue-3: #edf2fe;
  --blue-4: #dfeaff;
  --blue-5: #d0dfff;
  --blue-6: #bdd1ff;
  --blue-7: #a6bff9;
  --blue-8: #87a5ef;
  --blue-9: #3d63dd;
  --blue-10: #3657c3;
  --blue-11: #395bc7;
  --blue-12: #1d2e5c;

  --blue-a1: #00008002;
  --blue-a2: #0040ff08;
  --blue-a3: #0047f112;
  --blue-a4: #0058ff20;
  --blue-a5: #0052ff2f;
  --blue-a6: #004eff42;
  --blue-a7: #0048ee59;
  --blue-a8: #0040dd78;
  --blue-a9: #0032d2c2;
  --blue-a10: #002ab3c9;
  --blue-a11: #002cb7c6;
  --blue-a12: #001347e2;

  --blue-contrast: #fff;
  --blue-surface: #f5f8ffcc;
  --blue-indicator: #3d63dd;
  --blue-track: #3d63dd;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root,
    .light,
    .light-theme {
      --blue-1: oklch(99.4% 0.001 266.8);
      --blue-2: oklch(98.3% 0.008 266.8);
      --blue-3: oklch(96.1% 0.017 266.8);
      --blue-4: oklch(93.5% 0.034 266.8);
      --blue-5: oklch(90.3% 0.051 266.8);
      --blue-6: oklch(86.3% 0.071 266.8);
      --blue-7: oklch(80.7% 0.087 266.8);
      --blue-8: oklch(73% 0.113 266.8);
      --blue-9: oklch(54.3% 0.191 266.8);
      --blue-10: oklch(49.7% 0.173 266.8);
      --blue-11: oklch(51% 0.173 266.8);
      --blue-12: oklch(31.3% 0.086 266.8);

      --blue-a1: color(display-p3 0.02 0.02 0.51 / 0.008);
      --blue-a2: color(display-p3 0.02 0.267 0.878 / 0.032);
      --blue-a3: color(display-p3 0.008 0.239 0.886 / 0.067);
      --blue-a4: color(display-p3 0.004 0.306 0.937 / 0.118);
      --blue-a5: color(display-p3 0.004 0.278 0.933 / 0.173);
      --blue-a6: color(display-p3 0.004 0.247 0.937 / 0.244);
      --blue-a7: color(display-p3 0.004 0.231 0.871 / 0.33);
      --blue-a8: color(display-p3 0.004 0.204 0.808 / 0.448);
      --blue-a9: color(display-p3 0 0.153 0.773 / 0.726);
      --blue-a10: color(display-p3 0 0.129 0.655 / 0.761);
      --blue-a11: color(display-p3 0 0.133 0.667 / 0.746);
      --blue-a12: color(display-p3 0 0.059 0.251 / 0.871);

      --blue-contrast: #fff;
      --blue-surface: color(display-p3 0.961 0.973 1 / 0.8);
      --blue-indicator: oklch(54.3% 0.191 266.8);
      --blue-track: oklch(54.3% 0.191 266.8);
    }
  }
}

:root,
.light,
.light-theme {
  --gray-1: #fcfcfd;
  --gray-2: #f9f9fb;
  --gray-3: #eff0f3;
  --gray-4: #e7e8ec;
  --gray-5: #e0e1e6;
  --gray-6: #d8d9e0;
  --gray-7: #cdced7;
  --gray-8: #b9bbc6;
  --gray-9: #8b8d98;
  --gray-10: #80828d;
  --gray-11: #62636c;
  --gray-12: #1e1f24;

  --gray-a1: #00005503;
  --gray-a2: #00005506;
  --gray-a3: #00104010;
  --gray-a4: #000b3618;
  --gray-a5: #0009321f;
  --gray-a6: #00073527;
  --gray-a7: #00063332;
  --gray-a8: #00083046;
  --gray-a9: #00051d74;
  --gray-a10: #00051b7f;
  --gray-a11: #0002119d;
  --gray-a12: #000107e1;

  --gray-contrast: #ffffff;
  --gray-surface: #ffffffcc;
  --gray-indicator: #8b8d98;
  --gray-track: #8b8d98;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root,
    .light,
    .light-theme {
      --gray-1: oklch(99.1% 0.001 277.7);
      --gray-2: oklch(98.2% 0.003 277.7);
      --gray-3: oklch(95.6% 0.005 277.7);
      --gray-4: oklch(93.1% 0.006 277.7);
      --gray-5: oklch(91% 0.008 277.7);
      --gray-6: oklch(88.8% 0.009 277.7);
      --gray-7: oklch(85.3% 0.012 277.7);
      --gray-8: oklch(79.4% 0.016 277.7);
      --gray-9: oklch(64.6% 0.016 277.7);
      --gray-10: oklch(61% 0.016 277.7);
      --gray-11: oklch(50.3% 0.014 277.7);
      --gray-12: oklch(24.1% 0.01 277.7);

      --gray-a1: color(display-p3 0.024 0.024 0.349 / 0.012);
      --gray-a2: color(display-p3 0.024 0.024 0.349 / 0.024);
      --gray-a3: color(display-p3 0.008 0.067 0.255 / 0.063);
      --gray-a4: color(display-p3 0.012 0.051 0.216 / 0.095);
      --gray-a5: color(display-p3 0.004 0.039 0.2 / 0.122);
      --gray-a6: color(display-p3 0.004 0.027 0.18 / 0.153);
      --gray-a7: color(display-p3 0.008 0.027 0.184 / 0.197);
      --gray-a8: color(display-p3 0.004 0.031 0.176 / 0.275);
      --gray-a9: color(display-p3 0.004 0.02 0.106 / 0.455);
      --gray-a10: color(display-p3 0.004 0.02 0.098 / 0.499);
      --gray-a11: color(display-p3 0 0.008 0.059 / 0.616);
      --gray-a12: color(display-p3 0 0.004 0.027 / 0.883);

      --gray-contrast: #ffffff;
      --gray-surface: color(display-p3 1 1 1 / 80%);
      --gray-indicator: oklch(64.6% 0.016 277.7);
      --gray-track: oklch(64.6% 0.016 277.7);
    }
  }
}

/* DARK */

.dark,
.dark-theme,
:is(.dark, .dark-theme) :where(.radix-themes:not(.light, .light-theme)) {
  --color-background: #111;
  --header-background: var(--gray-2);
  --footer-background: var(--gray-2);
}

.dark,
.dark-theme {
  --blue-1: #0c111c;
  --blue-2: #111725;
  --blue-3: #172448;
  --blue-4: #1d2e61;
  --blue-5: #243974;
  --blue-6: #2d4484;
  --blue-7: #375098;
  --blue-8: #405eb2;
  --blue-9: #3d63dd;
  --blue-10: #3f5cb0;
  --blue-11: #93b4ff;
  --blue-12: #d5e2ff;

  --blue-a1: #0012fb0c;
  --blue-a2: #1156f916;
  --blue-a3: #2b64ff3b;
  --blue-a4: #3567ff56;
  --blue-a5: #3f71fd6b;
  --blue-a6: #4b7afd7c;
  --blue-a7: #5480ff91;
  --blue-a8: #5783ffad;
  --blue-a9: #4571ffdb;
  --blue-a10: #5580feab;
  --blue-a11: #93b4ff;
  --blue-a12: #d5e2ff;

  --blue-contrast: #fff;
  --blue-surface: #111d3980;
  --blue-indicator: #3d63dd;
  --blue-track: #3d63dd;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    .dark,
    .dark-theme {
      --blue-1: oklch(17.8% 0.025 266.8);
      --blue-2: oklch(20.7% 0.03 266.8);
      --blue-3: oklch(27.1% 0.069 266.8);
      --blue-4: oklch(31.8% 0.093 266.8);
      --blue-5: oklch(36.1% 0.105 266.8);
      --blue-6: oklch(40.4% 0.111 266.8);
      --blue-7: oklch(45% 0.12 266.8);
      --blue-8: oklch(50.3% 0.137 266.8);
      --blue-9: oklch(54.3% 0.191 266.8);
      --blue-10: oklch(49.7% 0.137 266.8);
      --blue-11: oklch(77.7% 0.123 266.8);
      --blue-12: oklch(91.1% 0.043 266.8);

      --blue-a1: color(display-p3 0 0.071 0.98 / 0.043);
      --blue-a2: color(display-p3 0.118 0.361 1 / 0.08);
      --blue-a3: color(display-p3 0.227 0.404 1 / 0.223);
      --blue-a4: color(display-p3 0.263 0.42 1 / 0.324);
      --blue-a5: color(display-p3 0.302 0.451 1 / 0.4);
      --blue-a6: color(display-p3 0.345 0.49 1 / 0.467);
      --blue-a7: color(display-p3 0.373 0.51 1 / 0.547);
      --blue-a8: color(display-p3 0.384 0.525 1 / 0.652);
      --blue-a9: color(display-p3 0.318 0.451 1 / 0.824);
      --blue-a10: color(display-p3 0.384 0.518 1 / 0.643);
      --blue-a11: color(display-p3 0.62 0.722 1 / 0.975);
      --blue-a12: color(display-p3 0.855 0.898 1 / 0.988);

      --blue-contrast: #fff;
      --blue-surface: color(display-p3 0.071 0.11 0.212 / 0.5);
      --blue-indicator: oklch(54.3% 0.191 266.8);
      --blue-track: oklch(54.3% 0.191 266.8);
    }
  }
}

.dark,
.dark-theme {
  --gray-1: #111113;
  --gray-2: #19191b;
  --gray-3: #222325;
  --gray-4: #292a2e;
  --gray-5: #303136;
  --gray-6: #393a40;
  --gray-7: #46484f;
  --gray-8: #5f606a;
  --gray-9: #6c6e79;
  --gray-10: #797b86;
  --gray-11: #b2b3bd;
  --gray-12: #eeeef0;

  --gray-a1: #1111bb03;
  --gray-a2: #cbcbf90b;
  --gray-a3: #d6e2f916;
  --gray-a4: #d1d9f920;
  --gray-a5: #d7ddfd28;
  --gray-a6: #d9defc33;
  --gray-a7: #dae2fd43;
  --gray-a8: #e0e3fd60;
  --gray-a9: #e0e4fd70;
  --gray-a10: #e3e7fd7e;
  --gray-a11: #eff0feb9;
  --gray-a12: #fdfdffef;

  --gray-contrast: #ffffff;
  --gray-surface: rgba(0, 0, 0, 0.05);
  --gray-indicator: #6c6e79;
  --gray-track: #6c6e79;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    .dark,
    .dark-theme {
      --gray-1: oklch(17.8% 0.004 277.7);
      --gray-2: oklch(21.5% 0.004 277.7);
      --gray-3: oklch(25.5% 0.005 277.7);
      --gray-4: oklch(28.4% 0.008 277.7);
      --gray-5: oklch(31.4% 0.009 277.7);
      --gray-6: oklch(35% 0.01 277.7);
      --gray-7: oklch(40.2% 0.012 277.7);
      --gray-8: oklch(49.2% 0.016 277.7);
      --gray-9: oklch(54% 0.017 277.7);
      --gray-10: oklch(58.6% 0.016 277.7);
      --gray-11: oklch(77% 0.014 277.7);
      --gray-12: oklch(94.9% 0.003 277.7);

      --gray-a1: color(display-p3 0.067 0.067 0.941 / 0.009);
      --gray-a2: color(display-p3 0.8 0.8 0.98 / 0.043);
      --gray-a3: color(display-p3 0.851 0.898 0.988 / 0.085);
      --gray-a4: color(display-p3 0.839 0.871 1 / 0.122);
      --gray-a5: color(display-p3 0.847 0.875 1 / 0.156);
      --gray-a6: color(display-p3 0.878 0.898 1 / 0.194);
      --gray-a7: color(display-p3 0.875 0.906 0.996 / 0.257);
      --gray-a8: color(display-p3 0.894 0.906 1 / 0.37);
      --gray-a9: color(display-p3 0.89 0.91 1 / 0.433);
      --gray-a10: color(display-p3 0.902 0.918 1 / 0.488);
      --gray-a11: color(display-p3 0.945 0.949 1 / 0.719);
      --gray-a12: color(display-p3 0.992 0.992 1 / 0.937);

      --gray-contrast: #ffffff;
      --gray-surface: color(display-p3 0 0 0 / 5%);
      --gray-indicator: oklch(54% 0.017 277.7);
      --gray-track: oklch(54% 0.017 277.7);
    }
  }
}
